<template>
  <section class="content">
    <table class="table table-hover" ref="tblcabang">
      <thead>
        <tr>
          <th>NAMA CABANG</th>
          <th>ALAMAT</th>
          <th>PHONE</th>
          <th>EMAIL</th>
          <th>FOTO</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">NAMA CABANG</label>
                  <input
                    id="name"
                    class="form-control"
                    v-model="form.name"
                    type="text"
                    required="required"
                    name="name"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">ALAMAT</label>
                  <input
                    id="address"
                    class="form-control"
                    v-model="form.address"
                    type="text"
                    required="required"
                    name="address"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">PHONE</label>
                  <input
                    id="phone"
                    class="form-control"
                    v-model="form.phone"
                    type="text"
                    required="required"
                    name="phone"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">EMAIL</label>
                  <input
                    id="email"
                    class="form-control"
                    v-model="form.email"
                    type="email"
                    required="required"
                    name="email"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">GOOGLE PAGE</label>
                  <input
                    id="google_page"
                    class="form-control"
                    v-model="form.google_page"
                    type="text"
                    name="google_page"
                  />
                </div>
                <div class="col-md-6 form-group">
                    <label for="inputFile">FOTO</label>
						<input type="hidden" name="img_code" value="" v-model="form.img_code" id="img_code" />
                    <div class="custom-file">
                        <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                        <label class="custom-file-label" for="inputFile">Choose file</label>
                    </div>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">STATUS</label> <br />
                  <input
                    id="status"
                    v-model="form.status"
                    type="checkbox"
                    name="status"
                  />
                  <label for="status">Active</label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Tambah Cabang",
      form: {
        name: "",
        address: "",
        phone: "",
        email: "",
        google_page: "",
        status: "",
        img_code: "",
      },
	  form_data: "",
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
	urlForm(url, method, data){
		const e = this.$refs;
		Swal.fire({
				title: "Simpan?",
				showCancelButton: true,
				confirmButtonText: `Ya`,
				cancelButtonText: "Tidak",
		}).then((result) => {
			if (result.isConfirmed) {
			authFetch(url, {
					method: method,
					body: data,
			})
				.then((res) => {
				return res.json();
				})
				.then((js) => {
				if (js.success) {
						Swal.fire("Proses Berhasil", ``, "success");
						$(e.formDialog).modal("hide");
						this.table.api().ajax.reload();
				} else {
						Swal.fire("Proses gagal", ``, "error");
						}
				});
			}
		});
	},
	submitForm: function(ev) {
        const e = this.$refs;
		const self = this;
		var data = Object.keys(self.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(self.form[key])).join('&');
		var urlSubmit = '/management/cabang';
		if (self.method == 'PUT') urlSubmit = '/management/cabang/' + self.form.id;

		var file_data = $('#inputFile').prop('files');
		if (file_data.length == 0) {
			console.log("upload kosong");
			self.urlForm(urlSubmit, self.method, data);
		} else {
		self.doUpload().then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {}
			return res.json();
        }).then(js => {
			console.log(js)
			self.errors = [];
			if (!js.status) {
				console.log(js.details)
				for (var key in js.details) {
					if (js.details.hasOwnProperty(key)) {
						self.errors.push(js.details[key])
					}
				}
				return;
			} else {
				if (js.message[0]) {
					self.form.img_code = js.message[0].code;
				}
				var data2 = Object.keys(self.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(self.form[key])).join('&');
				self.urlForm(urlSubmit, self.method, data2);
			}
    	});
		}
        ev.preventDefault();
    },
	uploadFoto : function (file) {
            var file_data = $('#inputFile').prop('files');
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
            });
            form_data.append('subdir', '/cabang/');
            form_data.append('id', this.form.id);
            this.form_data = form_data
            return false
    },
	doUpload: function() {
            return authFetch('/management/cabang/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                'Content-Type': null,
                }
            })
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblcabang, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/management/cabang",
      columns: [
        { data: "name" },
        { data: "address" },
        { data: "phone" },
        { data: "email" },
		{ data: "img_code",
		render: function (data, type, row, meta) {
			let img = '<img width="50px;" src="' +
					window.$apiUrl + "/file/image/" + data + '" v-if="todo.file_path">';
			if (row.file_path) {
				return img;
			} else {
				return '-';
			}
		}},
      ],
      filterBy: [0, 1],
      rowCallback: function (row, data) {
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Cabang";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.form.status = evt.data.status == 1 ? true : false; //operator ternary
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Cabang";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/management/cabang/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
